// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["field", "map", "latitude", "longitude" ]

  connect() {
    if(typeof(google) != "undefined"){
      this.initMap2();
    }
  }

  initMap2(){
    
    this.lat = document.getElementById('client_lat').value;
    this.lng = document.getElementById('client_long').value;
    this.latitudeTarget.value = this.lat;
    this.longitudeTarget.value = this.lng;

    if (!this.lat || !this.lng){
      this.lat = -17.392512;
      this.lng = -66.158999;
      document.getElementById('client_lat').value = this.lat;
      document.getElementById('client_long').value = this.lng;
    }
    var myCoords = new google.maps.LatLng(this.data.get("client_lat") || this.lat, this.data.get("client_long") || this.lng);
    var mapOptions = {
        center: myCoords,
        zoom: 14,
        detect_location: true,
        center_on_user: true
    };
    this.map = new google.maps.Map(this.mapTarget, mapOptions)
    this.marker = new google.maps.Marker({
        position: myCoords,
        animation: google.maps.Animation.DROP,
        map: this.map,
        draggable: true,
        // anchorPoint: new google.maps.Point(0, -29)
    });

    // refresh marker position and recenter map on marker
    function refreshMarker(){
        var lat = document.getElementById('client_lat').value;
        var lng = document.getElementById('client_long').value;
        var myCoords = new google.maps.LatLng(lat, lng);
        var position = new google.maps.LatLng(this.getPosition().lat(), this.getPosition().lng());
        this.marker.setPosition(myCoords);
        this.map.setCenter(position);   
    }

    // when input values change call refreshMarker
    document.getElementById('client_lat').onchange = refreshMarker;
    document.getElementById('client_long').onchange = refreshMarker;

    // when marker is dragged update input values
    this.marker.addListener('drag', function() {
        var lat = this.getPosition().lat();
        var lng = this.getPosition().lng();   
        var newlat = (Math.round(lat*1000000))/1000000;
        var newlng = (Math.round(lng*1000000))/1000000;
        document.getElementById('client_lat').value = newlat;
        document.getElementById('client_long').value = newlng;
    });

    // When drag ends, center (pan) the map on the marker position
    this.marker.addListener('dragend', function() {
        var coords = new google.maps.LatLng(this.getPosition().lat(), this.getPosition().lng());
        this.map.panTo(coords);  
    });
  }
}
