// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
//= require turbolinks
window.jQuery = $;
window.$ = $; 
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery") 
require("datatables.net-dt")
require('chart.js')

import 'bootstrap';
import '../stylesheets/application';
import "@fortawesome/fontawesome-free/js/all";
// require("src/gmaps_google")
// import "bootstrap-sprockets";
// import flatpickr from 'flatpickr';
// import 'flatpickr/dist/flatpickr.min.css'
// flatpickr(".datepicker", {

// });


document.addEventListener("turbolinks:load", () => {
    $('[data-toggle="tooltip"]').tooltip()
  })
  
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"

window.initMap2 = function(...args){
  const event = document.createEvent("Events")
  event.initEvent("google-maps-callback", true, true)
  event.args = args
  window.dispatchEvent(event)
}
